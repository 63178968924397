const Config = {};
Config.authorizationToken = 'c2F1bUBhbHR1c2pvYnMuY29tOkJpbGxpb25zNDAh';
Config.Headers = {
    Authorization: 'Basic ' + Config.authorizationToken,
    Accept: 'application/json',
    'Content-Type': 'application/json'
};
// Config.APIEndpoint = 'http://192.168.0.3:8080/'; 
Config.APIEndpoint = 'https://api.greenvalley.gazellia.com/';
// Config.APIEndpoint = 'http://green-valley-app.eba-cwrcc8m9.us-east-1.elasticbeanstalk.com/',
Config.WebSocket = 'ws://api.greenvalley.gazellia.com:3339/';
// Config.WebSocket = 'ws://192.168.0.3:3339/';  

export default Config;  