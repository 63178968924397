import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Database from '../services/database';

class YourPropertiesPage extends Component {
    state = {
        loading: false,
        properties: [],
        accountType: null,
    };

    async componentDidMount() {
        const properties = await this.getProperties();
        const accountType = JSON.parse(localStorage.getItem('AccountType'));

        this.setState({
            accountType: accountType,
            properties: properties || []
        });
    }

    async getProperties() {
        this.setState({ loading: true });
        let query = '';

        const accountType = JSON.parse(localStorage.getItem('AccountType'));
        const userId = JSON.parse(localStorage.getItem('User'));

        if (!userId || !userId.value) {
            console.error("User ID is missing from localStorage.");
            this.setState({ loading: false });
            return [];
        }

        if (accountType === 0) {
            query = `SELECT * FROM Properties WHERE userId = ${userId.value.id}`;
        } else if (accountType !== 3) {
            query = `SELECT * FROM Properties WHERE assigned_employee = ${userId.value.id}`;
        } else {
            query = `SELECT * FROM Properties`;
        }

        const params = {
            Method: 'query',
            Module: 'Database',
            params: { query }
        };

        try {
            const properties = await Database(params);
            return properties;
        } catch (error) {
            console.error("Failed to fetch properties:", error);
            return [];
        } finally {
            this.setState({ loading: false });
        }
    }

    render() {
        const { loading, properties, accountType } = this.state;

        return (
            <div className="container mx-auto py-5">
                {loading && (
                    <div className="loading">
                        <div className="spinner"></div>
                    </div>
                )}
                <div className="header-btn px-3 mb-5">
                    <Link to="/add-property" className="block w-fit py-2 px-4 bg-blue-600 text-sm text-white font-semibold rounded-md hover:bg-blue-700 focus:outline-none focus:ring focus:ring-blue-500">Add Property</Link>
                </div>

                <div className="px-3">
                    <div className="properties-list">
                        {properties.length > 0 ? (
                            properties.map((item) => (
                                <div key={item.id} className="property-card p-2 border-b border-gray-400">
                                    <Link to={`/service-request-list?propertyId=${item.id}&accountType=${accountType}`}>
                                        <h3 className="block text-gray-700 text-sm font-bold mb-1">{item.addressLine1}, {item.city}, {item.state} {item.zip}</h3>
                                        <p className="text-sm text-slate-500 dark:text-slate-400">
                                            Last Serviced On: {item.dateLastServiced ? moment(item.dateLastServiced).format('MMM DD, YYYY') : 'Never'}
                                        </p>
                                    </Link>
                                </div>
                            ))
                        ) : (
                            !loading && <p>No properties found.</p>
                        )}
                    </div>
                </div>

            </div>
        );
    }
}

export default YourPropertiesPage;
