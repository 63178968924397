import moment from 'moment';
import Database from './services/database';

const Helper = {
    // apiEndPoint: 'http://192.169.217.115:7099/partyfinder/',
    // apiEndPoint: 'http://192.168.0.3:8080/',
    apiEndPoint: 'https://api.greenvalley.gazellia.com/',
    // apiEndPoint: 'http://green-valley-app.eba-cwrcc8m9.us-east-1.elasticbeanstalk.com/',
    apiPostConfig: (obj) => { 
        return {
            method: 'POST',
            headers: {
                Authorization: 'Basic c2F1bUBhbHR1c2pvYnMuY29tOkJpbGxpb25zNDAh',
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(obj),
        }
    },
  
   
    saveLocalStorage: async(key, value) => {
        return new Promise(async(resolve, reject) => {
            await localStorage.setItem(key, JSON.stringify(value));
            resolve();
        });
    },
    isLoggedIn: async (openedApp, ref) => {
        try {
            const item = await localStorage.getItem('LoggedIn');
            const loggedIn = JSON.parse(item);
            if (openedApp) {
                if (loggedIn && loggedIn.status == 'true') {
                    Helper.goToPage('Dashboard', ref);
                }
            } else {
                if (!loggedIn || loggedIn.status == 'false') {
                    Helper.goToPage('Home', ref);
                }
            }
        } catch (e) {
            Helper.goToPage('Home', ref);
        }
    },
    goToPage: function (page, target, params) {
        target.props.navigation.navigate(page, params);
    },
    validateEmail: function (email) {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    },
    getTokenByUserId: async (userId) => {
        return new Promise (async (resolve, reject) => {
            var params = {
                Method: 'query',
                Module: 'Database',
                params: {
                    query: `SELECT a.device_id, b.token FROM Users a LEFT JOIN Tokens b ON b.uuid = a.device_id WHERE a.id = ${userId}`
                }
            };
    
            var result = await Database(params);
            resolve(result ? result[0].token : null);
        });
    },
    sendPushNotification: async (userId, message) => {
        return new Promise (async (resolve, reject) => {
            console.log('userId', userId);
            let token = await Helper.getTokenByUserId(userId);
            var params = {
                Method: 'SendPushNotificationIos',
                Module: 'Notifications',
                ChildModule: 'Main',
                params: {
                    tokens: [token],
                    message: message
                }
            };
            await Database(params);
            resolve();
        });
    },
    getUserByPropertyId: async (propertyId) => {
        return new Promise(async (resolve, reject) => {
            var params = {
                Method: 'query',
                Module: 'Database',
                params: {
                    query: `SELECT a.* FROM Users a LEFT JOIN Properties b ON b.userId = a.id WHERE b.id = ${propertyId}`
                }
            };
            var result = await Database(params);
            resolve(result ? result[0] : {});
        });
    },
    getUser: async () => {
        return new Promise(async (resolve, reject) => {
            var item = await localStorage.getItem('User');
            console.log("item",item);
            
            var user = JSON.parse(item);
            resolve(user);
        });
    },
    getUserId: async (email, fullAccount) => {
        return new Promise (async (resolve,reject) => {
            console.log('getting user func');
            if (!email) {
                var user = await Helper.getUser();
                var json = await Helper.getUserId(user.value.email);
                var userId = json[0].id;
                if (!fullAccount) {
                    resolve(userId);
                } else {
                    var query = 'SELECT * FROM Users WHERE id = "' + userId + '"';
                    var params = {
                        Method: 'query',
                        Module: 'Database',
                        params: {
                            query: query
                        }
                    };
    
                    var json = await Database(params);
                    resolve(json);
                }
            } else {
                var query = 'SELECT * FROM Users WHERE LOWER(email) = "' + email + '"';
                var params = {
                    Method: 'query',
                    Module: 'Database',
                    params: {
                        query: query
                    }
                };
    
                var resp = await Database(params);
                resolve(resp);
            }
        });
    },
    clearFormErrors: (ref, child) => {
        // console.log('child:', child); // Debugging line
    if (!child || !child.state) {
        console.error('Child or child.state is undefined');
        return;
    }
        var obj = {form: {}};
        Object.keys(child.state).forEach(property => {
            if (property.indexOf('error') > -1){
                delete child.state[property];
            }
       
        });
        if (Object.keys(obj.form).length > 0){
            ref.setState(child.state);
        }
    },
    capitalize: (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    },
    isFormValid: (child) => {
        if (!child || !child.state) {
            console.error('Child or child.state is undefined');
            return;
        }
        return new Promise (async (resolve, reject) => {
            let valid = true;
            setTimeout(() => {
                Object.keys(child.state).forEach((property, index) => {
                    if (property.indexOf('error') > -1){
                        valid = false;
                    }
                });
                resolve(valid);
            }, 500);
        });
    }, 
    updateFormElement: (type, text, ref) => {
        ref.setState(prevState => {
            let form = Object.assign({}, prevState.form);
            form[type].value = text;              
            return { form };
          })
    },
    titleCase: (str) => {
        var splitStr = str ? str.toLowerCase().split(' ') : [];
        for (var i = 0; i < splitStr.length; i++) {
            // You do not need to check if i is larger than splitStr length, as your for does that for you
            // Assign it back to the array
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
        } 
        // Directly return the joined string
        return splitStr.join(' '); 
    },
    sendEmail: async (params) => {
        var params = {
            Method: 'sendEmail',
            Module: 'Helper',
            params: {
                to: params.to,
                subject: 'Your password request',
                message: params.message,
                cc: params.cc
            }
        };
        await Database(params);
    },
    getDaysArray: function(year, month) {
        var monthIndex = month - 1; // 0..11 instead of 1..12
        var names = [ 'sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat' ];
        var date = new Date(year, monthIndex, 1);
        var result = [];
        while (date.getMonth() == monthIndex) {
          result.push(date.getDate() + '-' + names[date.getDay()]);
          date.setDate(date.getDate() + 1);
        }
        return result;
    },
    validateForm: async (ref, child) => {
        Helper.clearFormErrors(ref, child);
    
        const form = ref.state?.form || {}; // Ensure form exists

        Object.keys(form).forEach((property) => {
            let element = form[property];
            let value = element.value;
            let errorField = 'error-' + property;

            if (element.required) {
                let obj = {};
                if (!value) {
                    obj[errorField] = Helper.capitalize(property) + ' is required';
                } else {
                    switch (element.validation) {
                        case 'length':
                            if (element.min && value.length < element.min) {
                                obj[errorField] = Helper.capitalize(property) + ' must be greater than ' + (element.min - 1) + ' characters';
                            }
                            if (element.max && value.length > element.max) {
                                obj[errorField] = Helper.capitalize(property) + ' must be less than ' + (element.max + 1) + ' characters';
                            }
                            break;
                        case 'email':
                            if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/).test(value)) {
                                obj[errorField] = 'Email must be valid';
                            }
                            break;
                        case 'phone':
                            if (!/^\d{10}$/.test(value)) {
                                obj[errorField] = 'Phone number must be 10 digits';
                            }
                            break;
                        case 'equalTo':
                            if (value !== element.compare()) {
                                obj[errorField] = 'Must be equal to ' + Helper.capitalize(property.split('confirm')[1]);
                            }
                            break;
                        case 'dateValid':
                            if (!moment.isValid(new Date(value))) {
                                obj[errorField] = 'Date must be valid';
                            }
                            break;
                    }
                }
                child.setState(obj);
            }
        });

        let valid = await Helper.isFormValid(child);
        return valid;
    },
    
};

export default Helper;