import React, { useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import Database from '../services/database';

export const SignUp = () => {

    const navigate = useNavigate();
    const formRef = useRef(null);

    const [succssMessage, setSuccssMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const [form, setForm] = useState({
        email: { required: true, validation: 'email', value: '', error: '' },
        password: { required: true, validation: 'length', min: 5, max: 12, value: '', error: '' },
        confirmPassword: { required: true, validation: 'equalTo', value: '', error: '' },
        phone: { required: true, validation: 'phone', value: '', error: '' },
        addressLine1: { required: true, validation: 'length', min: 5, value: '', error: '' },
        city: { required: true, validation: 'length', min: 4, value: '', error: '' },
        state: { required: true, validation: 'length', min: 2, value: '', error: '' },
        zip: { required: true, validation: 'length', min: 5, max: 10, value: '', error: '' },
        comments: { required: false, validation: 'length', min: 4, value: '', error: '' },
        videoAttachment: { required: false, value: localStorage.getItem('ServiceRequestVideoPath') || '', error: '' },
        photoAttachment: { required: false, value: localStorage.getItem('ServiceRequestPhotoPath') || '', error: '' },
      });
      const [loading, setLoading] = useState(false);
      const [userId, setUserId] = useState(null);
      const [propertyId, setPropertyId] = useState(null);
      const [serviceRequestId, setServiceRequestId] = useState(null);
      const [photoId, setPhotoId] = useState(null);
      const [videoId, setVideoId] = useState(null);
    
      const handleChange = (e) => {
        const { name, value } = e.target;

        setForm((prevForm) => ({
            ...prevForm,
            [name]: { ...prevForm[name], value },
        }));
      };
    
      const handleFileChange = (e) => {
        const { name } = e.target;
        const file = e.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onloadend = () => {
            setForm((prevForm) => ({
              ...prevForm,
              [name]: { ...prevForm[name], value: reader.result },
            }));
          };
          reader.readAsDataURL(file);
        }
      };

        const validateForm = () => {
            const errors = {};

            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(form.email.value)) {
                errors.email = 'Please enter a valid email address';
            }

            if (form.password.value.length < 8) {
                errors.password = 'Password must be at least 8 characters long';
            }

            if (form.confirmPassword.value !== form.password.value) {
                errors.confirmPassword = 'Passwords do not match';
            }

            // const phoneRegex = /^\d{10}$/;
            // if (!form.phone.value || !phoneRegex.test(form.phone.value)) {
            //     errors.phone = 'Please enter a valid 10-digit phone number';
            // }

            setForm((prevForm) => ({
                ...prevForm,
                email: { ...prevForm.email, error: errors.email || '' },
                password: { ...prevForm.password, error: errors.password || '' },
                confirmPassword: { ...prevForm.confirmPassword, error: errors.confirmPassword || '' },
                phone: { ...prevForm.phone, error: errors.phone || '' },
            }));

            return Object.keys(errors).length === 0;
        };
    
      const submitRequest = async (e) => {
        e.preventDefault();
        
        if (validateForm()) {
          setLoading(true);
          try {
            const newUserId = await saveUser();
            await saveProperty(newUserId);
            // if (form.photoAttachment.value) await savePhotoAttachment();
            // if (form.videoAttachment.value) await saveVideoAttachment();
            //await saveServiceRequest();
            setSuccssMessage('Account created!');
            
            setTimeout(() => {
                setSuccssMessage('');
                navigate('Login')
            }, 2000);

          } catch (error) {
            console.error('Error submitting request:', error);
            setErrorMessage('Submitting Error Request');
            setTimeout(() => {
                setErrorMessage('');
            }, 2000);
          } finally {
            setLoading(false);
            
          }
        }
      };
    
      const saveUser = async () => {
        const params = {
          Method: 'insert',
          Module: 'Database',
          params: {
            table: 'Users',
            obj: {
              email: form.email.value,
              password: form.password.value,
              account_type: '0',
            },
          },
        };
        const newUserId = await Database(params);
        setUserId(newUserId);
        return newUserId;
      };
    
      const saveProperty = async (userId) => {
        const params = {
          Method: 'insert',
          Module: 'Database',
          params: {
            table: 'Properties',
            obj: {
              addressLine1: form.addressLine1.value,
              city: form.city.value,
              state: form.state.value,
              zip: form.zip.value,
              userId,
            },
          },
        };
        const newPropertyId = await Database(params);
        setPropertyId(newPropertyId);
        return newPropertyId;
      };
    
      const saveServiceRequest = async () => {
        const params = {
          Method: 'insert',
          Module: 'Database',
          params: {
            table: 'ServiceRequest',
            obj: { description: form.comments.value },
          },
        };
        const newServiceRequestId = await Database(params);
        setServiceRequestId(newServiceRequestId);
        return newServiceRequestId;
      };
    
      const savePhotoAttachment = async () => {
        console.log("photos",form.photoAttachment.value);

        const params = {
          Method: 'insert',
          Module: 'Database',
          params: {
            table: 'Photos',
            obj: { description: form.photoAttachment.value },
          },
        };
        const newPhotoId = await Database(params);
        setPhotoId(newPhotoId);
        return newPhotoId;
      };
    
      const saveVideoAttachment = async () => {
        const params = {
          Method: 'insert',
          Module: 'Database',
          params: {
            table: 'Videos',
            obj: { description: form.videoAttachment.value },
          },
        };
        const newVideoId = await Database(params);
        setVideoId(newVideoId);
        return newVideoId;
      };


  return (
    <div className="flex items-center justify-center py-20 bg-gray-100">
      <div className="w-full max-w-xl bg-white rounded-lg shadow-md p-8">
        <h2 className="text-2xl font-bold mb-6 text-center">Sign Up</h2>

        <form ref={formRef} onSubmit={submitRequest} className='grid grid-cols-2 gap gap-4'>

            <div className="">    
                <label className="block text-sm font-medium text-gray-700" htmlFor="email">
                Email
                </label>
                <input
                    type="email"
                    id="email"
                    name='email'
                    value={form.email.value}
                    onChange={handleChange}
                    
                    className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500"
                    placeholder="Enter your email"  
                />

              
                {form.email.error && <div className="text-red-500 text-xs">{form.email.error}</div>}
            </div>

            <div className="">
                <label className="block text-sm font-medium text-gray-700" >
                    Phone
                </label>
                <input
                    type="tel"
                    id="phone"
                    name='phone'
                    value={form.phone.value}
                    onChange={handleChange}
                    className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500"
                    placeholder="Enter your phone number"
                />
                {form.phone.error && <div className="text-red-500 text-xs">{form.phone.error}</div>}
            </div>

            <div className="">
                <label className="block text-sm font-medium text-gray-700" >
                    Password
                </label>
                <input
                type="password"
                name='password'
                value={form.password.value}
                onChange={handleChange}
                
                className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500"
                placeholder="Enter your password"
                />
                {form.password.error && <div className="text-red-500 text-xs">{form.password.error}</div>}
            </div>

            <div className="">
                <label className="block text-sm font-medium text-gray-700">
                    Confirm Password
                </label>
                <input
                    type="password"
                    name='confirmPassword'
                    value={form.confirmPassword.value}
                    onChange={handleChange}
                    
                    className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500"
                    placeholder="Confirm your password"
                />
               {form.confirmPassword.error && <div className="text-red-500 text-xs">{form.confirmPassword.error}</div>}
            </div>

            

            <div className="col-span-2">
                <label className="block text-sm font-medium text-gray-700">
                    Address
                </label>
                <input
                    type="text"
                    id="address"
                    name='addressLine1'
                    value={form.addressLine1.value}
                    onChange={handleChange}
                    
                    className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500"
                    placeholder="Enter your address"
                />
            </div>

            <div className='col-span-2'>
                <div className='grid grid-cols-3 gap-4'>
                    <div className="">
                        <label className="block text-sm font-medium text-gray-700" htmlFor="email">
                            City
                        </label>
                        <input
                            type="text"
                            id="city"
                            name='city'
                            value={form.city.value}
                            onChange={handleChange}
                            
                            className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500"
                            placeholder="Enter city"
                        />
                    </div>

                    <div className="">
                        <label className="block text-sm font-medium text-gray-700" htmlFor="email">
                            State
                        </label>
                        <input
                            type="text"
                            id="state"
                            name='state'
                            value={form.state.value}
                            onChange={handleChange}
                            
                            className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500"
                            placeholder="Enter state"
                        />
                    </div>

                    <div className="">
                        <label className="block text-sm font-medium text-gray-700" htmlFor="email">
                            Zip
                        </label>
                        <input
                            type="text"
                            id="zip"
                            name='zip'
                            value={form.zip.value}
                            onChange={handleChange}
                            
                            className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500"
                            placeholder="Enter zip"
                        />
                    </div>
                </div>
            </div>

            {/* <div className='col-span-2'>
                <label className="block text-sm font-medium text-gray-700" htmlFor="email">
                    Comments
                </label>

                <textarea 
                name='comments'
                value={form.comments.value}        
                onChange={handleChange} 
                className="mt-1 resize-none h-28 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500">
                </textarea>
            </div> */}

            {/* <div className='col-span-2'>
                <label className="block mb-2">Photo Attachment</label>
                <input type="file" name="photoAttachment" onChange={handleFileChange} className="block w-full p-2 mb-4 border rounded-md" />

                <label className="block mb-2">Video Attachment</label>
                <input type="file" name="videoAttachment" onChange={handleFileChange} className="block w-full p-2 mb-4 border rounded-md" />
            </div> */}

            <div className='col-span-2'>
                <button
                    type="submit"
                    disabled={loading}
                    className="w-full py-2 px-4 bg-blue-600 text-white font-semibold rounded-md hover:bg-blue-700 focus:outline-none focus:ring focus:ring-blue-500"
                >
                    {loading ? 'Submitting...' : 'Submit'}
                </button>
            </div>

            {succssMessage && (
                <div className='col-span-2'>
                    <p className='text-green-600 text-center pt-4 font-semibold'>
                        {succssMessage}!
                    </p>
                </div>
            )}
            {errorMessage && (
                <div className='col-span-2'>
                    <p className='text-red-600 text-center pt-4 font-semibold'>
                        {errorMessage}!
                    </p>
                </div>
            )}
          
        </form>
        <p className="mt-4 text-center text-sm">
          Already have an account?{' '}
          <Link to="/login" className="text-blue-600 hover:underline">
            Log in
          </Link>
        </p>
      </div>
    </div>
  )
}
