import React, { Component } from 'react';
import Helper from '../helper';
import Database from '../services/database';

class ForgotPasswordPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            email: '',
            error: '',
            success: '',
        };
    }

    sendPassword = async () => {
        const { email } = this.state;

        if (!email || !/\S+@\S+\.\S+/.test(email)) {
            this.setState({ error: 'Please enter a valid email address', success: '' });
            return;
        }

        this.setState({ loading: true, error: '', success: '' });

        try {
            const query = `SELECT password FROM Users WHERE email = "${email}"`;
            const params = {
                Method: 'query',
                Module: 'Database',
                params: { query },
            };

            const passwordData = await Database(params);

            if (passwordData && passwordData.length > 0) {
                const password = passwordData[0].password;
                // Simulate sending an email
                await Helper.sendEmail({ to: email, message: `Your password is ${password}.` });
                this.setState({ success: 'Password sent successfully to your email.', loading: false });
            } else {
                this.setState({ error: 'No account found with this email.', loading: false });
            }
        } catch (err) {
            this.setState({ error: 'An error occurred. Please try again later.', loading: false });
        }
    };

    render() {
        const { loading, email, error, success } = this.state;

        return (
            <div style={styles.container}>
                {loading && <div style={styles.loading}>Loading...</div>}

                <h2>Forgot Password</h2>

                {error && <div style={styles.error}>{error}</div>}
                {success && <div style={styles.success}>{success}</div>}

                <input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => this.setState({ email: e.target.value })}
                    style={styles.input}
                />

                <button onClick={this.sendPassword} style={styles.button}>
                    Send Password
                </button>
            </div>
        );
    }
}

export default ForgotPasswordPage;

const styles = {
    container: {
        padding: '20px',
        maxWidth: '400px',
        margin: '50px auto',
        textAlign: 'center',
    },
    input: {
        width: '100%',
        padding: '10px',
        marginBottom: '10px',
        border: '1px solid #ccc',
        borderRadius: '4px',
    },
    button: {
        padding: '10px 20px',
        backgroundColor: '#007bff',
        color: '#fff',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
    },
    error: {
        color: 'red',
        marginBottom: '10px',
    },
    success: {
        color: 'green',
        marginBottom: '10px',
    },
    loading: {
        marginBottom: '10px',
    },
};
