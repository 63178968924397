import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import Database from '../services/database';

export const AdminLogin = () => {

    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [succssMessage, setSuccssMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [password, setPassword] = useState('');

    const handleLogin = async (e) => {
        e.preventDefault();
        setSuccssMessage('');
        var params = {
            Method: 'login',
            Module: 'Account',
            params: {
                email: email,
                password: password,
                accountType: 3
            }
        };

        var result = await Database(params);

        if (result && result.msg == 'Login success') {
          setSuccssMessage('Logged in successfully!');
          setTimeout(() => {
            setSuccssMessage('');
            // navigate('Home');
          }, 2000);
        } else {
          setErrorMessage('Your login information is not correct');
          setTimeout(() => {
            setErrorMessage('');
          }, 2000);
        }

    };

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="w-full max-w-md bg-white rounded-lg shadow-md p-8">
        <h2 className="text-2xl font-bold mb-6 text-center">Login</h2>
        <form onSubmit={handleLogin}>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700" htmlFor="email">
              Username
            </label>
            <input
                type="text"
                id="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500"
                placeholder="Enter your Username"
            />
          </div>
          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700" htmlFor="password">
              Password
            </label>
            <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500"
                placeholder="Enter your password"
            />
          </div>
          <button
            type="submit"
            className="w-full py-2 px-4 bg-blue-600 text-white font-semibold rounded-md hover:bg-blue-700 focus:outline-none focus:ring focus:ring-blue-500"
          >
            Login
          </button>

          {succssMessage && (
            <div className='col-span-2'>
              <p className='text-green-600 text-center pt-4 font-semibold'>
                {succssMessage}!
              </p>
            </div>
          )}

          {errorMessage && (
            <div className='col-span-2'>
              <p className='text-red-600 text-center pt-4 font-semibold'>
                {errorMessage}!
              </p>
            </div>
          )}
        </form>
        
      </div>
    </div>
  )
}
