import React, { Component } from 'react';
import { useState, useEffect } from 'react';
import { Container, Button, Input, Alert, Table } from 'reactstrap'; // Using Reactstrap for styling
import Helper from '../helper';
import Database from '../services/database';
import { Link, useNavigate } from 'react-router-dom';

function AddPropertyPage(props) {
    const navigate = useNavigate();

    const [succssMessage, setSuccssMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [form, setForm] = useState({
        addressLine1: '',
        city: '',
        state: '',
        zip: '',
        comments: ''
    });
    const [errors, setErrors] = useState({});
    const [errorSubmitMessage, setErrorSubmitMessage] = useState('');

    useEffect(() => {
        setLoading(true);

        async function fetchData() {
            let assignmentId = props.assignmentId || localStorage.getItem('assignmentId');
            let accountType = localStorage.getItem('AccountType');

            let fetchedEmployees = await getEmployees();
            let fetchedAccounts = await getAccounts();
            setEmployees(fetchedEmployees);
            setAccounts(fetchedAccounts);
            setLoading(false);
        }

        fetchData();
    }, []);

    const getAccounts = async () => {
        let query = `SELECT * FROM Account`;
        let params = {
            Method: 'query',
            Module: 'Database',
            params: { query: query }
        };
        let result = await Database(params);
        return result;
    };

    const getEmployees = async () => {
        let query = `SELECT * FROM Users WHERE account_type = "1"`;
        let params = {
            Method: 'query',
            Module: 'Database',
            params: { query: query }
        };
        let result = await Database(params);
        return result;
    };

    const handleInputChange = (field, value) => {
        setForm({ ...form, [field]: value });
    };

    const toggleCheck = (type, index) => {
        let updated = type === 'employees' ? [...employees] : [...accounts];
        updated[index].isChecked = !updated[index].isChecked;
        type === 'employees' ? setEmployees(updated) : setAccounts(updated);
    };

    const isPropertySelected = () => {
        return employees.some(employee => employee.isChecked);
    };

    const submitRequest = async () => {
        let valid = Helper.validateForm(form);
        const updatedItems = employees.filter(item => item.isChecked);
        if(updatedItems.length == 0){
            setErrorSubmitMessage('Please choose both Assigned Account and Assigned Employee!');
        }

        if (valid && updatedItems.length) {
            await addProperty();
            setSuccssMessage('Property added!');
            navigate('/your-properties')
        }
    };

    const goToPage = (page) => {
        props.history.push(page);
    };

    const parseAddress = async () => {
        let params = {
            Method: 'getCityAndStateFromLocation',
            Module: 'Geocoder',
            params: {
                addressLine1: form.addressLine1,
                city: form.city,
                state: form.state,
                zip: form.zip
            }
        };
        let result = await Database(params);
        console.log("result",result);
        
        return result;
    };

    const addProperty = async () => {
        setLoading(true);
        let user = await Helper.getUser();
        let selectedEmployee = employees.find(emp => emp.isChecked)?.id;
        let selectedAccount = accounts.find(acc => acc.isChecked)?.id;
        let addressData = await parseAddress();
        let userId = JSON.parse(localStorage.getItem('User'));
        let params = {
            Method: 'insert',
            Module: 'Database',
            params: {
                table: 'Properties',
                obj: {
                    ...form,
                    userId: userId.value.id,
                    assigned_employee: selectedEmployee,
                    account_id: selectedAccount,
                    lat: addressData.lat,
                    lng: addressData.lng
                }
            }
        };
        await Database(params);
        setLoading(false);
    };

    return (
        <div className='bg-slate-50'>
            <Container className='max-w-3xl mx-auto py-5'>
                {loading && <div className="loading-spinner">Loading...</div>}

                <div className='flex justify-end mb-4'>
                    <Button className='block w-fit py-2 px-4 bg-blue-600 text-sm text-white font-semibold rounded-md hover:bg-blue-700 focus:outline-none focus:ring focus:ring-blue-500' onClick={() => navigate('/your-properties')}>
                        Your Properties
                    </Button>
                </div>

                <div className='w-full mx-auto bg-white rounded-lg shadow-md p-10'>
                    <h2 className='mb-3 font-medium text-xl text-gray-900'>Add Property</h2>

                    <div className='grid grid-cols-2 gap-x-5 gap-y-4 w-full'>
                        <div>
                            <label className='block text-gray-700 text-sm font-bold mb-2'>Address Line 1</label>
                            <Input
                                value={form.addressLine1}
                                onChange={e => handleInputChange('addressLine1', e.target.value)}
                                placeholder="Address Line 1"
                                className='mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500'
                            />
                            {errors.addressLine1 && <span className="error-text">{errors.addressLine1}</span>}
                        </div>

                        <div>
                            <label className='block text-gray-700 text-sm font-bold mb-2'>City</label>
                            <Input
                                value={form.city}
                                onChange={e => handleInputChange('city', e.target.value)}
                                placeholder="City"
                                className='mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500'
                            />
                            {errors.city && <span className="error-text">{errors.city}</span>}
                        </div>

                        <div>
                            <label className='block text-gray-700 text-sm font-bold mb-2'>State</label>
                            <Input
                                value={form.state}
                                onChange={e => handleInputChange('state', e.target.value)}
                                placeholder="State"
                                className='mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500'
                            />
                            {errors.state && <span className="error-text">{errors.state}</span>}
                        </div>

                        <div>
                            <label className='block text-gray-700 text-sm font-bold mb-2'>Zip</label>
                            <Input
                                value={form.zip}
                                onChange={e => handleInputChange('zip', e.target.value)}
                                placeholder="Zip"
                                className='mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500'
                            />
                            {errors.zip && <span className="error-text">{errors.zip}</span>}
                        </div>

                        <div className='col-span-2'>
                            <label className='block text-gray-700 text-sm font-bold mb-2'>Comments</label>
                            <Input
                                type="textarea"
                                value={form.comments}
                                onChange={e => handleInputChange('comments', e.target.value)}
                                placeholder="Comments"
                                className='mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500'
                            />
                        </div>
                    </div>

                    <h3 className='mb-3 mt-5 font-medium text-xl text-gray-900'>Assigned Account</h3>

                    <Table className='w-full border-b border-gray-300'>
                        <thead className='text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700'>
                            <tr>
                                <th className='px-2 py-3 text-left w-12'>Action</th>
                                <th className='px-2 py-3 text-left'>Full Name</th>
                                <th className='px-2 py-3 text-left'>Email Address</th>
                            </tr>
                        </thead>
                        <tbody className='divide-y-[1px] divide-gray-300'>
                            {accounts.map((account, index) => (
                                <tr key={account.id} onClick={() => toggleCheck('accounts', index)}>
                                    <td className='text-sm text-slate-500 dark:text-slate-400 p-2 w-12'>
                                        <input className='w-4 h-4 mt-1.5' type="checkbox" checked={account.isChecked || false} readOnly />
                                    </td>
                                    <td className='text-sm text-slate-500 dark:text-slate-400 p-2 w-64'>{account.name}</td>
                                    <td className='text-sm text-slate-500 dark:text-slate-400 p-2'>{account.email}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>

                    <h3 className='mb-3 mt-5 font-medium text-xl text-gray-900'>Assigned Employee</h3>

                    <Table className='w-full border-b border-gray-300'>
                        <thead className='text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700'>
                            <tr>
                                <th className='px-2 py-3 text-left w-12'>Action</th>
                                <th className='px-2 py-3 text-left'>Full Name</th>
                                <th className='px-2 py-3 text-left'>Email Address</th>
                            </tr>
                        </thead>
                        <tbody>
                            {employees.map((employee, index) => (
                                <tr key={employee.id} onClick={() => toggleCheck('employees', index)}>
                                    <td className='text-sm text-slate-500 dark:text-slate-400 p-2 w-12'>
                                        <input className='w-4 h-4 mt-1.5' type="checkbox" checked={employee.isChecked || false} readOnly />
                                    </td>
                                    <td className='text-sm text-slate-500 dark:text-slate-400 p-2 w-64'>{employee.first_name} {employee.last_name}</td>
                                    <td className='text-sm text-slate-500 dark:text-slate-400 p-2'>{employee.email}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>

                    <Button onClick={submitRequest} color=" mt-7 w-full py-2 px-4 bg-blue-600 text-white font-semibold rounded-md hover:bg-blue-700 focus:outline-none focus:ring focus:ring-blue-500">
                        Submit
                    </Button>
                </div>

                {succssMessage && (
                    <div className='col-span-2'>
                        <p className='text-green-600 text-center pt-4 font-semibold'>
                            {succssMessage}!
                        </p>
                    </div>
                )}
                {errorMessage && (
                    <div className='col-span-2'>
                        <p className='text-red-600 text-center pt-4 font-semibold'>
                            {errorMessage}!
                        </p>
                    </div>
                )}
                {errorSubmitMessage && (
                    <div className='col-span-2'>
                        <p className='text-red-600 text-center pt-4 font-semibold'>
                            {errorSubmitMessage}!
                        </p>
                    </div>
                )}
            </Container>
        </div>
    );
}

export default AddPropertyPage;
