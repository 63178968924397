import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Database from '../services/database';
import Helper from '../helper';

export const Login = () => {
    const navigate = useNavigate();
    const [userType, setUserType] = useState('Customer');
    const [succssMessage, setSuccssMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');




    const handleLogin = async (e) => {
        setSuccssMessage('');
        e.preventDefault();

        var params = {
            Method: 'login',
            Module: 'Account',
            params: {
                email: email,
                password: password,
                accountType: userType === 'Customer' ? 0 : 1
            }
        };

        var result = await Database(params);

        if (result && result.msg == 'Login success') {
            setSuccssMessage('Logged in successfully!');

            await Helper.saveLocalStorage('LoggedIn', {'status': 'true'});
            await Helper.saveLocalStorage('AccountType', result.user.account_type);
            var user = await Helper.getUserId(email.toLowerCase(), true);
            if (!user){
                Helper.alertHandler('Could not find user');
            } else {
                await Helper.saveLocalStorage('User', {value: user[0]});
                // if (!user[0].device_id){
                //     await this.saveDeviceId(user[0].id);
                // }
                // this.setState({loading: false}, () => {
                //     this.goToPage('Dashboard', {accountType: this.state.employeeTab ? 'Employee' : 'Customer'});
                // });
            }

            setTimeout(() => {
              setSuccssMessage('');
              navigate('/add-property')
            }, 3000);
            
           
        } else {
          setErrorMessage('Your login information is not correct');
          setTimeout(() => {
              setErrorMessage('');
          }, 3000);
            // alert('Your login information is not correct');
        }


    };

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="w-full max-w-md bg-white rounded-lg shadow-md p-8">
        <h2 className="text-2xl font-bold mb-6 text-center">Login</h2>
        <form onSubmit={handleLogin} className='border-b border-neutral-200 pb-8'>
            <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700" htmlFor="email">
                    Email
                </label>
                <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500"
                    placeholder="Enter your email"
                />
            </div>
          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700" htmlFor="password">
              Password
            </label>
            <input
                type="password"
                id="password"
                name='password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                autoComplete="current-password"
                className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500"
                placeholder="Enter your password"
            />
            <div className='text-right text-xs font-medium text-neutral-600 pt-1'>
                <Link to={'/forget-password'}>Forgot Password</Link>
            </div>
          </div>
          <button
            type="submit"
            className="w-full py-2 px-4 bg-blue-600 text-white font-semibold rounded-md hover:bg-blue-700 focus:outline-none focus:ring focus:ring-blue-500"
          >
            Login
          </button>

          {succssMessage && (
                <div className='col-span-2'>
                    <p className='text-green-600 text-center pt-4 font-semibold'>
                        {succssMessage}
                    </p>
                </div>
            )}
            {errorMessage && (
                <div className='col-span-2'>
                    <p className='text-red-600 text-center pt-4 font-semibold'>
                        {errorMessage}!
                    </p>
                </div>
            )}

          <p className='text-center pt-6'>
            <Link to={'/admin-login'} className="text-blue-600 inline-block hover:underline font-medium text-sm text-center">
                Admin Login
            </Link>
          </p>
        </form>
        <p className="mt-4 text-center text-sm">
          New Customer?{' '}
          <Link to={`/signup`} className="text-blue-600 hover:underline">
            Sign up here
          </Link>
        </p>

        <div className='grid grid-cols-2 gap-4 pt-12'>
            <button onClick={() => setUserType('Customer')} className={`${userType === 'Customer' ? 'bg-blue-600 text-white':' bg-white text-blue-600'} w-full py-1.5 px-3 border border-blue-600 font-semibold rounded text-sm`}>
            Customer
            </button>

            <button onClick={() => setUserType('Foreman')} className={`${userType === 'Foreman' ? 'bg-blue-600 text-white':' bg-white text-blue-600'} w-full py-1.5 px-3 border border-blue-600 font-semibold rounded`}>
                Foreman
            </button>
        </div>
      </div>
    </div>
  )
}
