import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import Helper from '../helper';
import Database from '../services/database';

const AddAssignmentPage = () => {
    const [succssMessage, setSuccssMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState({
        name: { required: true, validation: 'length', min: 5, value: '' },
        serviceDate: { required: true, validation: 'dateValid', value: '' },
    });
    const [serviceRequest, setServiceRequest] = useState(null);
    const [propertyId, setPropertyId] = useState(null);
    const [accountType, setAccountType] = useState(null);
    const [serviceRequestId, setServiceRequestId] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        // Parse query parameters from the URL
        const queryParams = new URLSearchParams(location.search);
        const propertyId = queryParams.get('propertyId');
        var accountType = queryParams.get('accountType');
        if (accountType == null) {
            accountType = JSON.parse(localStorage.getItem('AccountType'));
        }
        // Set state based on query params
        setPropertyId(propertyId);
        setAccountType(accountType);
        
        async function loadData() {
            setLoading(true);
            const serviceRequest = null; // Assume serviceRequest data fetching logic here
            setServiceRequest(serviceRequest);
            setServiceRequestId(serviceRequest?.id);
            setLoading(false);
        }
        loadData();
    }, [location.search]);

    const handleSubmit = async () => {
        const valid = Helper.validateForm(form);
        if (valid) {
            await addAssignment();
        }
    };

    const addAssignment = async () => {
        setLoading(true);
        const user = await Helper.getUser();
        
        const params = {
            Method: 'insert',
            Module: 'Database',
            params: {
                table: 'Assignment',
                obj: {
                    name: form.name.value,
                    user_id: user.id,
                    // next_service_date: serviceRequest?.next_service_date || '', 
                    property_id: propertyId,
                    job_status: 'Pending',
                },
            },
        };

        const assignmentId = await Database(params);
        
        if (assignmentId && serviceRequestId) {
            await Database({
                Method: 'update',
                Module: 'Database',
                params: {
                    table: 'ServiceRequest',
                    setAll: {
                        assignment_created: '1',
                        assignment_id: assignmentId,
                    },
                    id: serviceRequestId,
                },
            });
        }
        setSuccssMessage('Assignment added!');
        setLoading(false);
        navigate(`/assignment-list?propertyId=${propertyId}&accountType=${accountType}`);
    };

    return (
        <div className="flex flex-col h-dvh items-center justify-center bg-gray-100">
            {loading ? (
                <div className="spinner-border animate-spin text-blue-600" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            ) : (
                <>
                <form className="w-full max-w-md bg-white rounded-lg shadow-md p-8">
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                            Description
                        </label>
                        <input
                            type="text"
                            id="name"
                            placeholder="Enter Description"
                            value={form.name.value}
                            onChange={(e) => setForm({ ...form, name: { ...form.name, value: e.target.value } })}
                            className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500"
                        />
                    </div>

                    {/* <div className="mb-7">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="serviceDate">
                            Service Date
                        </label>
                        <input
                            type="text"
                            id="serviceDate"
                            placeholder="Service Date"
                            readOnly
                            value={serviceRequest?.next_service_date || ''}
                            className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500"
                        />
                    </div> */}

                    <button
                        type="button"
                        onClick={handleSubmit}
                        className={`w-full py-2 px-4 bg-blue-600 text-white font-semibold rounded-md hover:bg-blue-700 focus:outline-none focus:ring focus:ring-blue-500 ${form.name.value ? 'opacity-100 pointer-events-auto' : 'opacity-50 pointer-events-none'}`}
                    >
                        Create Assignment
                    </button>


                    
                    {succssMessage && (
                        <div className='col-span-2'>
                            <p className='text-green-600 text-center pt-4 font-semibold'>
                                {succssMessage}!
                            </p>
                        </div>
                    )}
                    {errorMessage && (
                        <div className='col-span-2'>
                            <p className='text-red-600 text-center pt-4 font-semibold'>
                                {errorMessage}!
                            </p>
                        </div>
                    )}
                </form>
                <Link className="max-w-[200px] mx-auto my-6 block text-center w-full py-2 px-4 bg-blue-600 text-white font-semibold rounded-md hover:bg-blue-700 focus:outline-none focus:ring focus:ring-blue-500" to={`/assignment-list?propertyId=${propertyId}&accountType=${accountType}`}>
                        Assignment List
                </Link>
                </>
            )}
        </div>
    );
};

export default AddAssignmentPage;
