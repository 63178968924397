import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Spinner, Button, Table, Alert } from 'reactstrap';
import moment from 'moment';
import Database from '../services/database'; // Assuming Database is the same service used in your React Native app

const ServiceRequestListPage = () => {
    const [succssMessage, setSuccssMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [serviceRequests, setServiceRequests] = useState([]);
    const [loading, setLoading] = useState(false);
    const [propertyId, setPropertyId] = useState(null);
    const [accountType, setAccountType] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const propertyId = queryParams.get('propertyId');
        const accountType = queryParams.get('accountType');

        setAccountType(accountType);
        setPropertyId(propertyId);
    }, [location]); // Adding location as dependency to re-fetch query params when location changes

    // Fetch assignments only if propertyId is set
    useEffect(() => {
        if (propertyId) {
            getServiceRequests(); // Fetch assignments once the propertyId is available
        }
    }, [propertyId]); // Adding propertyId as dependency to trigger this effect when it's updated

    const getServiceRequests = async () => {
        setLoading(true);
        const query = `SELECT * FROM ServiceRequest WHERE property_id = ${propertyId}`;
        const params = {
            Method: 'query',
            Module: 'Database',
            params: { query },
        };

        try {
            const serviceRequestsData = await Database(params);
            console.log('serviceRequestsData is', serviceRequestsData);
            setServiceRequests(serviceRequestsData);
        } catch (error) {
            console.error('Error fetching service requests:', error);
        } finally {
            setLoading(false);
        }
    };

    const goToAssignmentDetail = (assignmentId) => {
        if (assignmentId){
            navigate(`/assignment-details?assignmentId=${assignmentId}&propertyId=${propertyId}&accountType=${accountType}`);
        } else {
            setErrorMessage('This request is still pending assignment')
        }
    };

    const goToAddServiceRequestPage = () => {
        navigate(`/add-service-request?propertyId=${propertyId}`);
    };

    return (
        <div className="flex flex-col h-dvh items-center justify-center bg-gray-100">
            <div className="w-full max-w-2xl bg-white rounded-lg shadow-md p-8">
                <div style={styles.container}>
                    {loading && (
                        <div style={styles.loading}>
                            <Spinner color="primary" />
                        </div>
                    )}

                    {!loading && serviceRequests.length === 0 && (
                        <Alert color="info">
                            No service requests found for this property.
                        </Alert>
                    )}

                    <Button color="primary" onClick={goToAddServiceRequestPage} className="block w-fit py-1.5 px-4 bg-blue-600 text-sm text-white font-semibold rounded-md hover:bg-blue-700 focus:outline-none focus:ring focus:ring-blue-500">
                        Add Service Request
                    </Button>

                    <div className='w-full'>
                        <Table responsive style={styles.table} className='w-full border-b border-gray-200'>
                            <thead className='text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700'>
                                <tr>
                                    <th className='px-3 py-3 text-left'>Assignment</th>
                                    <th className='px-3 py-3 text-left'>Next Service</th>
                                </tr>
                            </thead>
                            <tbody className='divide-y-[1px] divide-gray-200'>
                                {serviceRequests.map(item => (
                                    
                                    <tr key={item.id} onClick={() => goToAssignmentDetail(item.assignment_id)} className='cursor-pointer'>
                                        <td  className='text-sm text-slate-500 dark:text-slate-400 px-2 py-3'>{item.description}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>

                    {succssMessage && (
                        <div className='col-span-2'>
                            <p className='text-green-600 text-center pt-4 font-semibold'>
                                {succssMessage}!
                            </p>
                        </div>
                    )}
                    {errorMessage && (
                        <div className='col-span-2'>
                            <p className='text-red-600 text-center pt-4 font-semibold'>
                                {errorMessage}!
                            </p>
                        </div>
                    )}

                </div>
            </div>
        </div>
    );
};

const styles = {
    container: {
        width: '100%',
        height: '50vh',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'center',
    },
    loading: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
    },
    table: {
        marginTop: '20px',
        flex: 1,
        overflowY: 'auto',
    },
};

export default ServiceRequestListPage;
