import React, { useState, useRef, useEffect } from "react";
import moment from "moment";
import Helper from '../helper';
import { useLocation } from 'react-router-dom';
import Database from '../services/database';

const UploadPage = () => {
  const [videoSuccssMessage, setVideoSuccssMessage] = useState('');
  const [imageSuccssMessage, setImageSuccssMessage] = useState('');
  const [commentSuccssMessage, setCommentSuccssMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [videoTab, setVideoTab] = useState(true);
  const [photoTab, setPhotoTab] = useState(false);
  const [commentTab, setCommentTab] = useState(false);
  const [previousPhoto, setPreviousPhoto] = useState(false);
  const [previousVideo, setPreviousVideo] = useState(false);
  const [attachmentMade, setAttachmentMade] = useState(false);
  const [attachmentType, setAttachmentType] = useState(null);
  const [mediaPath, setMediaPath] = useState("");
  const [loading, setLoading] = useState(false);
  const [recording, setRecording] = useState(false);
  const [newCustomer, setNewCustomer] = useState(null);
  
  const [videoUrl, setVideoUrl] = useState(null);
  const [photoUrl, setPhotoUrl] = useState(null);
  const [file, setFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState(null);

  // const [hasVideo, setHasVideo] = useState(null);
  // const [hasImage, setHasImage] = useState(null);

  const videoRef = useRef(null);
  const fileInputRef = useRef(null);
  const location = useLocation();
  const [assignmentId, setAssignmentId] = useState(null);
  const [files, setFiles] = useState(null);
  const [accountType, setAccountType] = useState('');
  const [assignmentDetailId, setAssignmentDetailId] = useState(null);
  const [form, setForm] = useState({
    comments: {
        required: false,
        validation: 'length',
        min: 1,
        value: '',
    },
  });
  const [viewComment, setViewComment] = useState({
    loading: false,
    comment: '',
  });

  const getAttachmentByType = async (type, assignmentId, assignmentDetailId) => {
    console.log('assignmentDetailId', assignmentDetailId);
    return new Promise(async (resolve, reject) => {
        var query = `SELECT id, path FROM ${type} WHERE assignment_detail_id = ${assignmentDetailId} AND assignment_id = ${assignmentId}`
            var params = {
                Method: 'query',
                Module: 'Database',
                params: {
                    query: query
                }
            };
            var result = await Database(params);
            resolve(result ? result[0] : null);
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const queryParams = new URLSearchParams(location.search);
      const assignmentId = queryParams.get('assignmentId');
      const assignmentDetailId = queryParams.get('assignmentDetailId');
      const accountType = queryParams.get('accountType');

      setAssignmentId(assignmentId);
      setAssignmentDetailId(assignmentDetailId);
      setAccountType(accountType);
      var localPhoto, localVideo, prevPhoto, prevVideo;
      var video = await getAttachmentByType('Videos', assignmentId, assignmentDetailId);
      if (video && video.path){
          localVideo = video;
          prevVideo = video;
          setPreviousVideo(video.path)
          console.log('prevVideo', prevVideo);
      }
      var photo = await getAttachmentByType('Photos', assignmentId, assignmentDetailId);
      if (photo && photo.path){
          localPhoto = photo;
          prevPhoto = photo;
          setPreviousPhoto(photo.path)
      }

      console.log("prevPhoto",prevPhoto);     
      

      setFiles({videoPath: (localVideo && localVideo.path) ? localVideo.path : '',photoPath: (localPhoto && localPhoto.path) ? localPhoto.path : '', prevVideo: prevVideo, prevPhoto: prevPhoto});
      
    };

    fetchData();
    getComment();

  }, []);

  useEffect(() => {
    if (assignmentId && assignmentDetailId) {
        getCommentId();
    }
  }, [assignmentId, assignmentDetailId]);

  

  const toggleTab = (tab) => {
    if (tab === "video") {
      setVideoTab(true);
      setPhotoTab(false);
      setCommentTab(false);
    } else if(tab === "photo") {
      setPhotoTab(true);
      setVideoTab(false);
      setCommentTab(false);
    } else{
      setCommentTab(true);
      setPhotoTab(false);
      setVideoTab(false);
    }
    setAttachmentMade(false);
    setMediaPath("");
  };

  const startOver = () => {
    setAttachmentMade(false);
    setMediaPath("");
  };

  const uploadMedia = async () => {
    if (!mediaPath) return;
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", mediaPath);
      formData.append("fileName", `${moment().format()}-${videoTab ? "video" : "photo"}`);

      const response = await fetch("https://your-api-endpoint.com/upload", {
        method: "POST",
        headers: {
          Authorization: "Basic your-encoded-credentials",
        },
        body: formData,
      });

      if (response.ok) {
        console.log("Media uploaded successfully.");
        // Navigate back or show success message
      } else {
        console.error("Upload failed:", response.statusText);
      }
    } catch (error) {
      console.error("Error during upload:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && videoTab) {
      setFile(selectedFile);
      const videoUrl = URL.createObjectURL(selectedFile);
      setVideoUrl(videoUrl); // Display the video before uploading
    }
    if (selectedFile && photoTab) {
      setFile(selectedFile);
      const photoUrl = URL.createObjectURL(selectedFile);
      setPhotoUrl(photoUrl); // Display the video before uploading
    }
  };
  
  const uploadVideo = async () => {
    if (!file) {
      setError("No file selected.");
      return;
    }
    console.log("upload");
    
  
    setIsUploading(true);
    setError(null);
  
    // Step 1: Prepare file details and metadata as done on mobile
    const user = await Helper.getUser();
    const userIdResponse = await Helper.getUserId(user.value.email);
    const userId = userIdResponse[0]?.id;
    const name = `${userId}-${moment().format()}`;
  
    // Step 2: Prepare FormData for upload
    const formData = new FormData();
    formData.append("file", file, `${name}.mp4`);  // Including a filename for the uploaded file
    formData.append("user_id", userId);
    formData.append("assignment_id", assignmentId); // Adjust if `assignmentId` is available
    formData.append("assignment_detail_id", assignmentDetailId);
  
    try {
      // Step 3: Upload video file
      const response = await fetch(Helper.apiEndPoint, {
        method: "POST",
        headers: {
          Authorization: "Basic c2F1bUBhbHR1c2pvYnMuY29tOkJpbGxpb25zNDAh",
          Accept: "application/json",
        },
        body: formData,
      });

      console.log("response",response);
      
  
      if (response.status == 200) {
        setVideoSuccssMessage('Video uploaded successfully!');
      }
  
      // Step 4: Process server response

      const result = await response.text();
      console.log("result",result);
      
      const savedPath = `${Helper.apiEndPoint}${result}`;
      console.log("Video uploaded successfully at:", savedPath);
  
      // Step 5: Save video path locally (if needed)
      await Helper.saveLocalStorage("ServiceRequestVideoPath", { path: savedPath });
  
      // Step 6: Optional: Update or insert entry in database
      const dbRequest = {
        Method: files.prevVideo ? 'update' : 'insert',
        Module: "Database",
        params: {
          table: "Videos",
          obj: {
            user_id: userId,
            assignment_id: assignmentId,
            assignment_detail_id: assignmentDetailId,
            path: savedPath,
          },
        },
      };
      
      // Check if this is an update (when prevVideo is defined)
      if (files.prevVideo) {
        // Set `setAll` to the object with fields to update, and add `id` for the specific record
        dbRequest.params.setAll = dbRequest.params.obj;
        dbRequest.params.id = files.prevVideo.id;
      }
      
      console.log("dbRequest", dbRequest);
      
      // Send the database update request
      const dbResponse = await fetch(Helper.apiEndPoint, {
        method: "POST",
        headers: {
          Authorization: "Basic c2F1bUBhbHR1c2pvYnMuY29tOkJpbGxpb25zNDAh",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dbRequest),
      });
      
      console.log("dbResponse", dbResponse);
      
      if (dbResponse.ok) {
        console.log("Video path saved to DB successfully");
        setIsUploading(false);
        // Additional actions, e.g., redirecting to a previous page
      } else {
        throw new Error(`DB save failed with status ${dbResponse.status}`);
      }
    }
     catch (error) {
      console.error("Error uploading video:", error);
      setError("Video upload failed.");
      setIsUploading(false);
    }
  };

  const uploadImage = async () => {
    if (!file) {
      setError("No file selected.");
      return;
    }
    console.log("upload");
    
  
    setIsUploading(true);
    setError(null);
  
    // Step 1: Prepare file details and metadata as done on mobile
    const user = await Helper.getUser();
    const userIdResponse = await Helper.getUserId(user.value.email);
    const userId = userIdResponse[0]?.id;
    const name = `${userId}-${moment().format()}`;
  
    // Step 2: Prepare FormData for upload
    const formData = new FormData();
    formData.append("file", file, `${name}.png`);  // Including a filename for the uploaded file
    formData.append("user_id", userId);
    formData.append("assignment_id", assignmentId); // Adjust if `assignmentId` is available
    formData.append("assignment_detail_id", assignmentDetailId);
  
    try {
      // Step 3: Upload video file
      const response = await fetch(Helper.apiEndPoint, {
        method: "POST",
        headers: {
          Authorization: "Basic c2F1bUBhbHR1c2pvYnMuY29tOkJpbGxpb25zNDAh",
          Accept: "application/json",
        },
        body: formData,
      });

      console.log("response",response);
      
  
      if (response.status == 200) {
        setImageSuccssMessage('Image uploaded successfully!');
      }
  
      // Step 4: Process server response

      const result = await response.text();
      console.log("result",result);
      
      const savedPath = `${Helper.apiEndPoint}${result}`;
      console.log("Image uploaded successfully at:", savedPath);
  
      // Step 5: Save Image path locally (if needed)
      await Helper.saveLocalStorage("ServiceRequestVideoPath", { path: savedPath });
      console.log("files.prevPhoto",files.prevPhoto);
      
      // Step 6: Optional: Update or insert entry in database
      const dbRequest = {
        Method: files.prevPhoto ? 'update' : 'insert',
        Module: "Database",
        params: {
          table: 'Photos',
          obj: {
            user_id: userId,
            assignment_id: assignmentId,
            assignment_detail_id: assignmentDetailId,
            path: savedPath,
          },
        },
      };
      
      // Check if this is an update (when prevPhoto or prevVideo is defined)
      if (files.prevPhoto) {
        // Set `setAll` to the object with fields to update, and add `id` for the specific record
        dbRequest.params.setAll = dbRequest.params.obj;
        dbRequest.params.id = files.prevPhoto.id;  // Or `prevVideo.id` if that’s the correct variable
      }
      
      console.log("dbRequest", dbRequest);
      
      // Send the database update request
      const dbResponse = await fetch(Helper.apiEndPoint, {
        method: "POST",
        headers: {
          Authorization: "Basic c2F1bUBhbHR1c2pvYnMuY29tOkJpbGxpb25zNDAh",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dbRequest),
      });
      
      console.log("dbResponse", dbResponse);
      
      if (dbResponse.ok) {
        console.log("Image path saved to DB successfully");
        setIsUploading(false);
        // Additional actions, e.g., redirecting to a previous page
      } else {
        throw new Error(`DB save failed with status ${dbResponse.status}`);
      }
    }
     catch (error) {
      console.error("Error uploading Image:", error);
      setError("Image upload failed.");
      setIsUploading(false);
    }
  };

  const getCommentId = async () => {
    setLoading(true);
    const params = {
        Method: 'query',
        Module: 'Database',
        params: {
            table: 'Comments',
            query: `SELECT id, comments FROM Comments WHERE assignment_id = "${assignmentId}" AND assignment_details_id = "${assignmentDetailId}"`,
        },
    };

    let response = await Database(params);
    setLoading(false);

    console.log(response);
    

    // if (response.status == 200) {
    //   setCommentSuccssMessage('Comment uploaded successfully!');
    // }

    if (response.length > 0) {
        setForm(prevForm => ({
            ...prevForm,
            comments: {
                ...prevForm.comments,
                value: response[0].comments
            }
        }));
        console.log(response);
        return response[0].id;
    } else {
        console.log('No comment found');
        return null;
    }
    
  };

  const saveComment = async () => {
    setLoading(true);
    let commentId = await getCommentId(); // Get the existing comment ID if it exists
    console.log('form.comments.value:', form.comments.value);

    const params = {
        Method: commentId ? 'update' : 'insert',
        Module: 'Database',
        params: {
            table: 'Comments',
            obj: {
                comments: form.comments.value,
                assignment_id: assignmentId,
                assignment_details_id: assignmentDetailId,
            },
        },
    };

    // If updating an existing comment, add `setAll` and `id` fields
    if (commentId) {
        params.params.setAll = params.params.obj;
        params.params.id = commentId;

        setCommentSuccssMessage('Comment uploaded successfully!');
    }

    await Database(params);
    setLoading(false);

    getCommentId();

  };

  const handleCommit = (e) => {
    const newValue = e.target.value;
    setForm(prevForm => ({
        ...prevForm,
        comments: {
            ...prevForm.comments,
            value: newValue
        }
    }));

    console.log(form.comments.value);
    
  };

  const getComment = async () => {
    setLoading(true);
    console.log("get comment");
    
    try {
        const queryParams = new URLSearchParams(location.search);
        const assignmentId = queryParams.get('assignmentId');
        const assignmentDetailId = queryParams.get('assignmentDetailId');
        console.log("assignmentId",assignmentId);
        console.log("assignmentDetailId",assignmentDetailId);
        
        if (!assignmentId || !assignmentDetailId) {
            setViewComment((prevState) => ({ ...prevState, loading: false, comment: '' }));
            return;
        }
  
        const query = `SELECT * FROM Comments WHERE assignment_id = ${assignmentId} AND assignment_details_id = ${assignmentDetailId}`;
  
        const params = {
            Method: 'query',
            Module: 'Database',
            params: {
                query: query
            }
        };
        console.log("params",params);
        
        const json = await Database(params);
        console.log("json",json);
        
        const hasResults = json && json.length > 0;
        const comment = hasResults ? json[0].comments : '';
        console.log("comment",comment);
        
        setViewComment((prevState) => ({
            ...prevState,
            loading: false,
            comment: comment
        }));
  
    } catch (error) {
        setViewComment((prevState) => ({ ...prevState, loading: false, comment: '' }));
    } finally {
        setLoading(false);
    }
  };

  return (
    <div className="mb-10 mx-auto max-w-lg">
        <h1 className="text-center py-5 text-xl text-medium">Upload Details</h1>
        
      <div className="flex justify-center items-center mb-6">
        <button className={`bg-[${videoTab ? "#97c83e" : "#ffffff"}] px-4 py-2 text-sm text-${videoTab ? "[#ffffff]" : "[#97c83e]"} font-normal tracking-wide rounded`} onClick={() => toggleTab("video")}>Video</button>
        <button className={`bg-[${photoTab ? "#97c83e" : "#ffffff"}] px-4 py-2 text-sm text-${photoTab ? "[#ffffff]" : "[#97c83e]"} font-normal tracking-wide rounded`} onClick={() => toggleTab("photo")}>Image</button>
        <button className={`bg-[${commentTab ? "#97c83e" : "#ffffff"}] px-4 py-2 text-sm text-${commentTab ? "[#ffffff]" : "[#97c83e]"} font-normal tracking-wide rounded`} onClick={() => toggleTab("comment")}>Comment</button>
      </div>

      {/* {!attachmentMade && (
        <div>
          <input
            ref={fileInputRef}
            type="file"
            accept={videoTab ? "video/*" : "image/*"}
            capture={videoTab ? "camcorder" : "camera"}
            style={{ display: "none" }}
            onChange={handleCapture}
          />
          <button
            onClick={() => fileInputRef.current.click()}
            style={{
              padding: "15px",
              backgroundColor: "#97c83e",
              color: "#fff",
              border: "none",
              borderRadius: "50%",
              cursor: "pointer",
            }}
          >
            {videoTab ? (recording ? "Stop" : "Record") : "Capture"}
          </button>
        </div>
      )} */}

      {attachmentMade && (
        <div>
          {attachmentType === "video" ? (
            <video
              ref={videoRef}
              controls
              src={mediaPath}
              style={{ width: "100%", height: "auto", marginTop: "20px" }}
            />
          ) : (
            <img
              src={mediaPath}
              alt="Captured"
              style={{ width: "100%", height: "auto", marginTop: "20px" }}
            />
          )}

          <div style={{ display: "flex", justifyContent: "space-around", marginTop: "20px" }}>
            <button
              onClick={startOver}
              style={{ padding: "10px 20px", backgroundColor: "#97c83e", color: "#fff" }}
            >
              Start Over
            </button>
            <button
              onClick={uploadMedia}
              style={{ padding: "10px 20px", backgroundColor: "#97c83e", color: "#fff" }}
            >
              Save
            </button>
          </div>
        </div>
      )}

      <div>
        <label className="relative flex flex-col justify-center items-center p-6 rounded bg-slate-100 min-h-52" htmlFor="upload_file">
        {videoTab &&(
            <input id="upload_file" type="file" accept="video/*" onChange={handleFileChange} className="hidden"/>
          )}
          {photoTab &&(
            <input id="upload_file" type="file" accept="image" onChange={handleFileChange} className="hidden"/>
          )}
            {/* {error && <p style={{ color: "red" }}>{error}</p>} */}

            {/* {file == null && (!previousVideo || !previousPhoto) && !commentTab && <div className="text-base text-slate-500 font-normal">Upload File</div>} */}
            
            {/* {commentTab && <div className="text-base text-slate-800 font-medium pb-3">Upload Comment</div>} */}
            
            {!isUploading && videoTab && previousVideo && !videoUrl && (
                <div>
                  <video width="500" controls>
                        <source src={previousVideo ? previousVideo: videoUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            )}
            {videoUrl && !isUploading && videoTab && (
                <div>
                    <video width="500" controls>
                        <source src={videoUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            )}
            {!isUploading && photoTab && previousPhoto && !photoUrl && (
                <div>
                  <img src={previousPhoto ? previousPhoto: photoUrl} alt=""/>
                </div>
            )}
            {!isUploading && photoTab && (
                <div>
                  <img src={photoUrl} alt=""/>
                </div>
            )}

            {commentTab && (
              <div className="w-full">
                  <input type="text" id="comment" placeholder="Comments" value={form.comments.value} onChange={handleCommit} className="bg-white p-3 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500"/>
              </div>
            )}

            {!commentTab && (
              <div className="bg-[#97c83e] px-4 py-2 text-sm text-white font-medium rounded-2xl cursor-pointer mt-6">Select File</div>
            )}
          </label>

        {videoSuccssMessage && videoTab && (
            <div className='col-span-2'>
                <p className='text-green-600 text-center pt-4 font-semibold'>
                    {videoSuccssMessage}!
                </p>
            </div>
        )}
        {imageSuccssMessage && photoTab && (
            <div className='col-span-2'>
                <p className='text-green-600 text-center pt-4 font-semibold'>
                    {imageSuccssMessage}!
                </p>
            </div>
        )}
        {commentSuccssMessage && commentTab && (
            <div className='col-span-2'>
                <p className='text-green-600 text-center pt-4 font-semibold'>
                    {commentSuccssMessage}!
                </p>
            </div>
        )}
        {errorMessage && (
            <div className='col-span-2'>
                <p className='text-red-600 text-center pt-4 font-semibold'>
                    {errorMessage}!
                </p>
            </div>
        )}

        {/* Upload Button */}
          {videoTab && (
            <button className="block mx-auto my-6 bg-[#97c83e] px-6 py-2 text-sm text-white font-medium tracking-widest rounded" onClick={uploadVideo} disabled={isUploading}>
                {isUploading ? "Uploading..." : "Upload Video"}
            </button>
          )}
          {photoTab && (
            <button className="block mx-auto my-6 bg-[#97c83e] px-6 py-2 text-sm text-white font-medium tracking-widest rounded" onClick={uploadImage} disabled={isUploading}>
                {isUploading ? "Uploading..." : "Upload Image"}
            </button>
          )}
          {commentTab && (
            <button className="block mx-auto my-6 bg-[#97c83e] px-6 py-2 text-sm text-white font-medium tracking-widest rounded" onClick={saveComment}>
              Save Comment
            </button>
          )}
        </div>
    </div>
  );

};

export default UploadPage;