import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Helper from '../helper';

const LogOutPage = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const logout = async () => {
            // Clear user data from localStorage
            await Helper.saveLocalStorage('LoggedIn', null);
            await Helper.saveLocalStorage('User', null);

            setLoading(false); // Hide loading after logout
            navigate('/login'); // Redirect to login after logout
        };

        logout();
    }, [navigate]);

    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', backgroundColor: '#fff', position: 'relative' }}>
            <img
                src={require('../img/page-bg.jpg')}
                alt="Background"
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    zIndex: -1,
                }}
            />
            {loading && (
                <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div className="spinner" style={{ fontSize: '24px', fontWeight: 'bold' }}>
                        Logging Out...
                    </div>
                </div>
            )}
        </div>
    );
};

export default LogOutPage;
