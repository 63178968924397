import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import { SignUp } from './Auth/SignUp';
import { Login } from './Auth/Login';
import { AdminLogin } from './Auth/AdminLogin';
import { Home } from './Home';
import AddAssignmentPage from './Pages/AddAssignmentPage';
import AssignmentListPage from './Pages/AssignmentListPage';
import AddServiceRequestPage from './Pages/AddServiceRequestPage';
import ServiceRequestListPage from './Pages/ServiceRequestListPage';
import AddPropertyPage from './Pages/AddProperty';
import YourPropertiesPage from './Pages/YourProperties';
import AssignmentDetailPage from './Pages/AssignmentDetailPage';
import Upload from './Pages/Upload';
import ForgetPasswordPage from './Pages/ForgetPasswordPage';
import Header from './Auth/Header';
import LogOutPage from './Auth/LogOutPage';

// Helper function to check if user is logged in
const isAuthenticated = () => {
  const loggedIn = localStorage.getItem('LoggedIn');
  if (!loggedIn) {
      return false; // If no data is found, user is not authenticated
  }

  try {
      const parsed = JSON.parse(loggedIn);
      return parsed.status === 'true'; // Check if status is 'true'
  } catch (error) {
      console.error('Error parsing LoggedIn data:', error);
      return false; // Return false if parsing fails
  }
};


// PrivateRoute Component
const PrivateRoute = ({ children }) => {
    return isAuthenticated() ? children : <Navigate to="/login" replace />;
};

// Layout for pages with Header
const HeaderLayout = ({ children }) => (
    <>
        <Header />
        {children}
    </>
);

function App() {
    return (
        <Router>
            <Routes>
                {/* Public Routes */}
                <Route path="/login" element={<Login />} />
                <Route path="/admin-login" element={<AdminLogin />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/forget-password" element={<ForgetPasswordPage />} />
                <Route path="/logout" element={<LogOutPage />} />

                {/* Protected Routes */}
                <Route
                    path="/home"
                    element={
                        <PrivateRoute>
                            <HeaderLayout>
                                <Home />
                            </HeaderLayout>
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/add-assignment"
                    element={
                        <PrivateRoute>
                            <HeaderLayout>
                                <AddAssignmentPage />
                            </HeaderLayout>
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/assignment-list"
                    element={
                        <PrivateRoute>
                            <HeaderLayout>
                                <AssignmentListPage />
                            </HeaderLayout>
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/add-property"
                    element={
                        <PrivateRoute>
                            <HeaderLayout>
                                <AddPropertyPage />
                            </HeaderLayout>
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/your-properties"
                    element={
                        <PrivateRoute>
                            <HeaderLayout>
                                <YourPropertiesPage />
                            </HeaderLayout>
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/assignment-details"
                    element={
                        <PrivateRoute>
                            <HeaderLayout>
                                <AssignmentDetailPage />
                            </HeaderLayout>
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/service-request-list"
                    element={
                        <PrivateRoute>
                            <HeaderLayout>
                                <ServiceRequestListPage />
                            </HeaderLayout>
                        </PrivateRoute>
                    }
                />
                 <Route
                    path="/add-service-request"
                    element={
                        <PrivateRoute>
                            <HeaderLayout>
                                <AddServiceRequestPage />
                            </HeaderLayout>
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/upload"
                    element={
                        <PrivateRoute>
                            <HeaderLayout>
                                <Upload />
                            </HeaderLayout>
                        </PrivateRoute>
                    }
                />

                {/* Fallback route */}
                <Route path="*" element={<Navigate to="/login" replace />} />
            </Routes>
        </Router>
    );
}

export default App;
