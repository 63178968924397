import React, { useState, useEffect } from 'react';
import Helper from '../helper';
import { useNavigate } from 'react-router-dom'; 

const Header = () => {
    const [user, setUser] = useState({
        email: '',
        first_name: '',
        last_name: '',
        profile_img: '',
    });

    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            const userLog = await Helper.getUser();
            console.log(userLog.value.email);
            
            if (userLog.value) {
                setUser((prevData) => ({
                    ...prevData,
                    email: userLog.value.email || '',
                    first_name: userLog.value.first_name || '',
                    last_name: userLog.value.last_name || '',
                    profile_img: userLog.value.profile_img || '',
                }));
            }
        };

        fetchData();
    }, []);

    const handleLogout = () => {
        navigate('/logout');
    };

    return (
        <div className='sticky top-0 left-0 z-10 bg-white flex items-center px-5 py-2.5 shadow-sm'>
            <img src={require('../img/logo.png')} alt='GreenValley' className='max-h-8'/>
            <div className='flex items-center ml-auto'>
                <img src={require('../img/avatar.png')} alt='Avatar' className='max-h-7'/>
                <div className='flex flex-col items-start pl-2.5'>
                    <span className='text-[10px] leading-tight text-slate-500'>{user.email}</span>
                    <span className='inline-block text-xs text-blue-500 leading-4 cursor-pointer' onClick={handleLogout}>Logout</span>
                </div>
            </div>
        </div>
    );
}

export default Header;
